import React, { useState } from "react"
import { Link, withPrefix, navigate } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { REST, API_MAP } from "../components/apply/api-calls-map"

const isBrowser = typeof window !== "undefined"

const domain =
  isBrowser &&
  window &&
  window.location &&
  window.location.origin &&
  window.location.origin &&
  window.location.origin === "http://local.dev.trustic.com:8000"
    ? "http://dev.trustic.com"
    : isBrowser && window.location.origin

/*global dataLayer */
const QuarantorPersonalLoans = ({ data }) => {
  const breakpoints = useBreakpoint()

  const [stepLoading, setStepLoading] = useState(false)

  const initLoan = async () => {
    setStepLoading(true)
    const [res, status] = await REST({
      url: `${domain}/origination/api/public/init/loanApplication${
        localStorage.getItem("moneyReason")
          ? `?purpose=${localStorage.getItem("moneyReason")}`
          : ""
      }`,
      method: "POST",
    })

    if (status === 200) {
      document.body.click()
      document.getElementById("loan-purpose-message").style.display = "none"
      localStorage.setItem("businessId", res.businessId)
      dataLayer.push({
        borrower_app_business_id: res.businessId,
      })
      navigate("/apply")
    }
  }

  return (
    <>
      <Layout
        home
        dark={true}
        headerComponent={
          breakpoints.md ? (
            <header
              className="header h-fullscreen text-white"
              style={{
                backgroundImage: `url(${withPrefix(
                  "/pexels-cottonbro-4881619.jpeg"
                )})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div className="overlay opacity-60 bg-dark" />
              <div className="container">
                <div className="row align-items-center h-100">
                  <div className="col-md-10 m-auto text-center">
                    <h1 className="fw-600" style={{ zoom: "0.9" }}>
                      Guarantor Personal Loans
                    </h1>

                    <p className="lead-3">
                      Borrow up to $3,000 with a guarantor at affordable fixed
                      rates.
                    </p>
                    <div className="dropdown mb-4 mt-5">
                      <button
                        id="moneyReason"
                        className="btn btn-xl btn-rounded btn-light mw-250 dropdown-toggle px-2"
                        data-toggle="dropdown"
                        style={{
                          maxWidth: "220px",
                          width: "100%",
                          fontSize: "10px",
                          zIndex: 2,
                        }}
                      >
                        Choose your loan purpose
                      </button>
                      <p
                        id="loan-purpose-message"
                        className="mb-0"
                        style={{ display: "none" }}
                      >
                        <small className="text-danger">
                          Please select a loan purpose!
                        </small>
                      </p>
                      <div
                        className="dropdown-menu"
                        style={{
                          maxWidth: "286px",
                          width: "100%",
                          transformOrigin: "center center",
                          zIndex: 2,
                        }}
                      >
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => {
                            document.body.click()
                            document.getElementById(
                              "loan-purpose-message"
                            ).style.display = "none"
                            localStorage.setItem(
                              "moneyReason",
                              "Pay for unexpected expense"
                            )
                            document.getElementById(
                              "moneyReason"
                            ).innerText = localStorage.getItem("moneyReason")

                            dataLayer.push({
                              money_reason_guarantor_personal_loans:
                                "Pay for unexpected expense",
                            })
                          }}
                        >
                          Pay for unexpected expense
                        </a>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => {
                            document.body.click()
                            document.getElementById(
                              "loan-purpose-message"
                            ).style.display = "none"
                            localStorage.setItem(
                              "moneyReason",
                              "Cover medical expenses"
                            )
                            document.getElementById(
                              "moneyReason"
                            ).innerText = localStorage.getItem("moneyReason")

                            dataLayer.push({
                              money_reason_guarantor_personal_loans:
                                "Cover medical expenses",
                            })
                          }}
                        >
                          Cover medical expenses
                        </a>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => {
                            document.body.click()
                            document.getElementById(
                              "loan-purpose-message"
                            ).style.display = "none"
                            localStorage.setItem(
                              "moneyReason",
                              "Cover educational expenses"
                            )
                            document.getElementById(
                              "moneyReason"
                            ).innerText = localStorage.getItem("moneyReason")

                            dataLayer.push({
                              money_reason_guarantor_personal_loans:
                                "Cover educational expenses",
                            })
                          }}
                        >
                          Cover educational expenses
                        </a>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => {
                            document.body.click()
                            document.getElementById(
                              "loan-purpose-message"
                            ).style.display = "none"
                            localStorage.setItem(
                              "moneyReason",
                              "Pay for home improvement"
                            )
                            document.getElementById(
                              "moneyReason"
                            ).innerText = localStorage.getItem("moneyReason")

                            dataLayer.push({
                              money_reason_guarantor_personal_loans:
                                "Pay for home improvement",
                            })
                          }}
                        >
                          Pay for home improvement{" "}
                        </a>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => {
                            document.body.click()
                            document.getElementById(
                              "loan-purpose-message"
                            ).style.display = "none"
                            localStorage.setItem(
                              "moneyReason",
                              "Pay for car repair"
                            )
                            document.getElementById(
                              "moneyReason"
                            ).innerText = localStorage.getItem("moneyReason")

                            dataLayer.push({
                              money_reason_guarantor_personal_loans:
                                "Pay for car repair",
                            })
                          }}
                        >
                          Pay for car repair
                        </a>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => {
                            document.body.click()
                            document.getElementById(
                              "loan-purpose-message"
                            ).style.display = "none"
                            localStorage.setItem(
                              "moneyReason",
                              "Pay off expensive debt"
                            )
                            document.getElementById(
                              "moneyReason"
                            ).innerText = localStorage.getItem("moneyReason")

                            dataLayer.push({
                              money_reason_guarantor_personal_loans:
                                "Pay off expensive debt",
                            })
                          }}
                        >
                          Pay off expensive debt
                        </a>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => {
                            document.body.click()
                            document.getElementById(
                              "loan-purpose-message"
                            ).style.display = "none"
                            localStorage.setItem(
                              "moneyReason",
                              "Pay for a vacation"
                            )
                            document.getElementById(
                              "moneyReason"
                            ).innerText = localStorage.getItem("moneyReason")

                            dataLayer.push({
                              money_reason_guarantor_personal_loans:
                                "Pay for a vacation",
                            })
                          }}
                        >
                          Pay for a vacation
                        </a>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => {
                            document.body.click()
                            document.getElementById(
                              "loan-purpose-message"
                            ).style.display = "none"
                            localStorage.setItem("moneyReason", "Buy a new car")
                            document.getElementById(
                              "moneyReason"
                            ).innerText = localStorage.getItem("moneyReason")

                            dataLayer.push({
                              money_reason_guarantor_personal_loans:
                                "Buy a new car",
                            })
                          }}
                        >
                          Buy a new car
                        </a>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => {
                            document.body.click()
                            document.getElementById(
                              "loan-purpose-message"
                            ).style.display = "none"
                            localStorage.setItem(
                              "moneyReason",
                              "Pay to move or relocate"
                            )
                            document.getElementById(
                              "moneyReason"
                            ).innerText = localStorage.getItem("moneyReason")

                            dataLayer.push({
                              money_reason_guarantor_personal_loans:
                                "Pay to move or relocate",
                            })
                          }}
                        >
                          Pay to move or relocate
                        </a>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => {
                            document.body.click()
                            document.getElementById(
                              "loan-purpose-message"
                            ).style.display = "none"
                            localStorage.setItem("moneyReason", "Other")
                            document.getElementById(
                              "moneyReason"
                            ).innerText = localStorage.getItem("moneyReason")

                            dataLayer.push({
                              money_reason_guarantor_personal_loans: "Other",
                            })
                          }}
                        >
                          Other
                        </a>
                      </div>
                    </div>
                    <a
                      className="btn btn-xl btn-rounded btn-danger mw-250"
                      href="/apply"
                      onClick={e => {
                        e.preventDefault()
                        dataLayer.push({ event: "check_your_rate_landing" })
                        if (localStorage.getItem("moneyReason")) {
                          initLoan()
                        } else {
                          document.getElementById(
                            "loan-purpose-message"
                          ).style.display = "block"
                        }
                      }}
                    >
                      {stepLoading ? (
                        <div
                          className="loader"
                          style={{
                            borderColor: "rgb(255 255 255 / 20%)",
                            borderLeftColor: "#fff",
                            fontSize: "2.4px",
                          }}
                        />
                      ) : (
                        "Check your rate"
                      )}
                    </a>
                    <p className="mt-3 mb-0 fs-13">
                      This won’t impact your credit score.
                    </p>
                  </div>
                </div>
              </div>
            </header>
          ) : (
            <header className="header h-fullscreen text-white">
              <video
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  opacity: 1,
                }}
                className="overlay"
                src={withPrefix("/production ID_5044221.mp4")}
                autoPlay
                muted
                loop
                preload="metadata"
                playsInline
              ></video>
              <div className="overlay opacity-60 bg-dark" />
              <div className="container">
                <div className="row align-items-center h-100">
                  <div className="col-md-10 m-auto text-center">
                    <h1 className="fw-600 display-3">
                      Guarantor Personal Loans
                    </h1>
                    <br />
                    <p className="lead-4">
                      Borrow up to $3,000 with a guarantor at affordable fixed
                      rates.
                    </p>
                    <br />
                    <div className="dropdown" style={{}}>
                      <button
                        id="moneyReason"
                        className="btn btn-xl btn-rounded btn-light mw-250 dropdown-toggle px-2"
                        data-toggle="dropdown"
                        style={{
                          maxWidth: "220px",
                          width: "100%",
                          fontSize: "10px",
                          transform: "scale(1.3)",
                          transformOrigin: "center",
                          zIndex: 2,
                        }}
                      >
                        Choose your loan purpose
                      </button>
                      <p
                        id="loan-purpose-message"
                        className="mb-0"
                        style={{ display: "none" }}
                      >
                        <small className="text-danger">
                          Please select a loan purpose!
                        </small>
                      </p>
                      <div
                        className="dropdown-menu"
                        style={{
                          maxWidth: "286px",
                          width: "100%",
                          transform: "scale(1.3)",
                          transformOrigin: "center",
                          zIndex: 2,
                        }}
                      >
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => {
                            document.body.click()
                            document.getElementById(
                              "loan-purpose-message"
                            ).style.display = "none"
                            localStorage.setItem(
                              "moneyReason",
                              "Pay for unexpected expense"
                            )
                            document.getElementById(
                              "moneyReason"
                            ).innerText = localStorage.getItem("moneyReason")

                            dataLayer.push({
                              money_reason_guarantor_personal_loans:
                                "Pay for unexpected expense",
                            })
                          }}
                        >
                          Pay for unexpected expense
                        </a>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => {
                            document.body.click()
                            document.getElementById(
                              "loan-purpose-message"
                            ).style.display = "none"
                            localStorage.setItem(
                              "moneyReason",
                              "Cover medical expenses"
                            )
                            document.getElementById(
                              "moneyReason"
                            ).innerText = localStorage.getItem("moneyReason")

                            dataLayer.push({
                              money_reason_guarantor_personal_loans:
                                "Cover medical expenses",
                            })
                          }}
                        >
                          Cover medical expenses
                        </a>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => {
                            document.body.click()
                            document.getElementById(
                              "loan-purpose-message"
                            ).style.display = "none"
                            localStorage.setItem(
                              "moneyReason",
                              "Cover educational expenses"
                            )
                            document.getElementById(
                              "moneyReason"
                            ).innerText = localStorage.getItem("moneyReason")

                            dataLayer.push({
                              money_reason_guarantor_personal_loans:
                                "Cover educational expenses",
                            })
                          }}
                        >
                          Cover educational expenses
                        </a>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => {
                            document.body.click()
                            document.getElementById(
                              "loan-purpose-message"
                            ).style.display = "none"
                            localStorage.setItem(
                              "moneyReason",
                              "Pay for home improvement"
                            )
                            document.getElementById(
                              "moneyReason"
                            ).innerText = localStorage.getItem("moneyReason")

                            dataLayer.push({
                              money_reason_guarantor_personal_loans:
                                "Pay for home improvement",
                            })
                          }}
                        >
                          Pay for home improvement{" "}
                        </a>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => {
                            document.body.click()
                            document.getElementById(
                              "loan-purpose-message"
                            ).style.display = "none"
                            localStorage.setItem(
                              "moneyReason",
                              "Pay for car repair"
                            )
                            document.getElementById(
                              "moneyReason"
                            ).innerText = localStorage.getItem("moneyReason")

                            dataLayer.push({
                              money_reason_guarantor_personal_loans:
                                "Pay for car repair",
                            })
                          }}
                        >
                          Pay for car repair
                        </a>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => {
                            document.body.click()
                            document.getElementById(
                              "loan-purpose-message"
                            ).style.display = "none"
                            localStorage.setItem(
                              "moneyReason",
                              "Pay off expensive debt"
                            )
                            document.getElementById(
                              "moneyReason"
                            ).innerText = localStorage.getItem("moneyReason")

                            dataLayer.push({
                              money_reason_guarantor_personal_loans:
                                "Pay off expensive debt",
                            })
                          }}
                        >
                          Pay off expensive debt
                        </a>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => {
                            document.body.click()
                            document.getElementById(
                              "loan-purpose-message"
                            ).style.display = "none"
                            localStorage.setItem(
                              "moneyReason",
                              "Pay for a vacation"
                            )
                            document.getElementById(
                              "moneyReason"
                            ).innerText = localStorage.getItem("moneyReason")

                            dataLayer.push({
                              money_reason_guarantor_personal_loans:
                                "Pay for a vacation",
                            })
                          }}
                        >
                          Pay for a vacation
                        </a>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => {
                            document.body.click()
                            document.getElementById(
                              "loan-purpose-message"
                            ).style.display = "none"
                            localStorage.setItem("moneyReason", "Buy a new car")
                            document.getElementById(
                              "moneyReason"
                            ).innerText = localStorage.getItem("moneyReason")

                            dataLayer.push({
                              money_reason_guarantor_personal_loans:
                                "Buy a new car",
                            })
                          }}
                        >
                          Buy a new car
                        </a>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => {
                            document.body.click()
                            document.getElementById(
                              "loan-purpose-message"
                            ).style.display = "none"
                            localStorage.setItem(
                              "moneyReason",
                              "Pay to move or relocate"
                            )
                            document.getElementById(
                              "moneyReason"
                            ).innerText = localStorage.getItem("moneyReason")

                            dataLayer.push({
                              money_reason_guarantor_personal_loans:
                                "Pay to move or relocate",
                            })
                          }}
                        >
                          Pay to move or relocate
                        </a>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => {
                            document.body.click()
                            document.getElementById(
                              "loan-purpose-message"
                            ).style.display = "none"
                            localStorage.setItem("moneyReason", "Other")
                            document.getElementById(
                              "moneyReason"
                            ).innerText = localStorage.getItem("moneyReason")

                            dataLayer.push({
                              money_reason_guarantor_personal_loans: "Other",
                            })
                          }}
                        >
                          Other
                        </a>
                      </div>
                    </div>
                    <br />
                    <a
                      className="btn btn-xl btn-rounded btn-danger mw-250"
                      href="/apply"
                      style={{
                        transform: "scale(1.3)",
                        transformOrigin: "center",
                      }}
                      onClick={e => {
                        e.preventDefault()
                        dataLayer.push({ event: "check_your_rate_landing" })
                        if (localStorage.getItem("moneyReason")) {
                          initLoan()
                        } else {
                          document.getElementById(
                            "loan-purpose-message"
                          ).style.display = "block"
                        }
                      }}
                    >
                      {stepLoading ? (
                        <div
                          className="loader"
                          style={{
                            borderColor: "rgb(255 255 255 / 20%)",
                            borderLeftColor: "#fff",
                            fontSize: "2.4px",
                          }}
                        />
                      ) : (
                        "Check your rate"
                      )}
                    </a>
                    <p className="mt-3 mb-0 fs-16">
                      This won’t impact your credit score.
                    </p>
                  </div>
                </div>
              </div>
            </header>
          )
        }
      >
        <section className="section pt-7 pb-3 text-center">
          <div className="container">
            <div className="row">
              <div className="col-md-12 mx-auto">
                <div className="row">
                  <div className="col-md-6">
                    <h5 style={{ textTransform: "uppercase" }}>
                      <b className="fw-800">Loan amounts range from</b>
                    </h5>
                    <h2 className="display-4">
                      <span className="text-dark">
                        <b className="fw-800">$1,100&nbsp; - &nbsp;$3,000</b>
                      </span>
                    </h2>
                  </div>
                  <div className="col-md-6">
                    <h5 style={{ textTransform: "uppercase" }}>
                      <b className="fw-800">APRs range from</b>
                    </h5>
                    <h2 className="display-4">
                      <span className="text-dark">
                        <b className="fw-800">9.95%&nbsp; - &nbsp;35.99%</b>
                      </span>
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-md-12 mx-auto">
                <p className="fs-16">
                  Loan origination fee up to 5%. Fixed monthly repayments over
                  36 months. No prepayment penalties.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="section py-0 text-center">
          <div className="container">
            <div className="row">
              <div className="col-md-10 mx-auto">
                <p className="lead">
                  A representative example: You receive a loan of $1,500
                  repayable over 36 monthly installments with an interest rate
                  of 15% and a 5% origination fee of $75, for an APR of 18.67%.
                  In this example, $1,425 will be given to your guarantor as
                  your agent and you will make 36 monthly payments of $52.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="section py-7 text-center">
          <div className="container">
            <h3 className="fw-600 mb-6 display-5">
              Why choose a guarantor loan from Trustic?
            </h3>
            <div className="row mt-4">
              <div className="col-md-10 mx-auto">
                <p className="lead-3">
                  If you are looking for a straightforward affordable personal
                  loan from a trusted direct lender, you’ve come to the right
                  place. At Trustic we believe that credit shouldn’t cost an arm
                  and a leg, and that everyone should have access to affordable
                  credit. However, the traditional ways to assess someone’s
                  creditworthiness based on their credit profiles is not working
                  for many people. Trustic allows borrowers to reduce the cost
                  of their loans by sharing credit risk with other people
                  through guarantor nomination, thus allowing to borrow
                  affordably right now. If a friend or family member trusts you
                  to make loan payments, we can trust you too.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="section py-7 text-center">
          <div className="container">
            <h3 className="fw-600 mb-6 display-5">How Trustic works</h3>
            <div className="row gap-y">
              <div className="col-md-6 col-xl-3">
                <div
                  className="card card-body shadow-3 text-center px-3"
                  style={{ minHeight: "255px" }}
                >
                  <p className="my-5 text-default">
                    <div className="step-icon" style={{ width: "auto" }}>
                      <span
                        className="iconbox bg-dark text-white"
                        style={{ borderRadius: "3px" }}
                      >
                        1
                      </span>
                    </div>
                  </p>
                  <h5 className="mt-2 mb-5 fw-500">Tell us about yourself</h5>
                </div>
              </div>
              <div className="col-md-6 col-xl-3">
                <div
                  className="card card-body shadow-3 text-center px-3"
                  style={{ minHeight: "255px" }}
                >
                  <p className="my-5 text-default">
                    <div className="step-icon" style={{ width: "auto" }}>
                      <span
                        className="iconbox bg-dark text-white"
                        style={{ borderRadius: "3px" }}
                      >
                        2
                      </span>
                    </div>
                  </p>
                  <h5 className="mt-2 mb-5 fw-500">
                    Choose your loan offer, and repayment option your like best
                  </h5>
                </div>
              </div>
              <div className="col-md-6 col-xl-3">
                <div
                  className="card card-body shadow-3 text-center px-3"
                  style={{ minHeight: "255px" }}
                >
                  <p className="my-5 text-default">
                    <div className="step-icon" style={{ width: "auto" }}>
                      <span
                        className="iconbox bg-dark text-white"
                        style={{ borderRadius: "3px" }}
                      >
                        3
                      </span>
                    </div>
                  </p>
                  <h5 className="mt-2 mb-5 fw-500">
                    Let us know who your guarantor is
                  </h5>
                </div>
              </div>
              <div className="col-md-6 col-xl-3">
                <div
                  className="card card-body shadow-3 text-center px-3"
                  style={{ minHeight: "255px" }}
                >
                  <p className="my-5 text-default">
                    <div className="step-icon" style={{ width: "auto" }}>
                      <span
                        className="iconbox bg-dark text-white"
                        style={{ borderRadius: "3px" }}
                      >
                        4
                      </span>
                    </div>
                  </p>
                  <h5 className="mt-2 mb-5 fw-500">Receive funds</h5>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section pt-7 pb-8 bg-gray text-center">
          <div className="container">
            <h3 className="fw-600 mb-6 display-5">
              What our customers are telling about us:
            </h3>
            <div className="row gap-y">
              <div className="col-md-6">
                <div className="card shadow-3">
                  <div className="card-body px-6">
                    <div className="rating mb-3">
                      <label className="fa fa-star active" />
                      <label className="fa fa-star active" />
                      <label className="fa fa-star active" />
                      <label className="fa fa-star active" />
                      <label className="fa fa-star active" />
                    </div>
                    <p
                      className="text-quoted mb-5"
                      style={{ minHeight: "56px" }}
                    >
                      The lender that sees good in everything and everyone
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card shadow-3">
                  <div className="card-body px-6">
                    <div className="rating mb-3">
                      <label className="fa fa-star active" />
                      <label className="fa fa-star active" />
                      <label className="fa fa-star active" />
                      <label className="fa fa-star active" />
                      <label className="fa fa-star active" />
                    </div>
                    <p className="text-quoted mb-5">
                      As a guarantor, you are given a unique opportunity to
                      empower your friend or family member to stand on their
                      feet
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section py-7 text-center">
          <div className="container">
            <h3 className="fw-600 mb-6 display-5">
              How do I find a Guarantor?
            </h3>
            <div className="row">
              <div className="col-md-10 mx-auto text-center">
                <p className="lead-3">
                  Pretty much anyone can be a guarantor. A friend, a family
                  member, a colleague, you name it. The guarantor should be
                  someone who knows you well, and you should trust each other.
                  They should also meet our minimum criteria:
                </p>
                <div className="col-md-8 mx-auto">
                  <div className="card card-body border shadow-3 text-center px-4 py-5 mt-6 mb-7">
                    <ul className="text-left text-md-left lead-3 mb-0">
                      <li>
                        {/* <i className="ti-check mr-2" /> */}
                        <span>Aged at least 18 years old</span>
                      </li>
                      <li>
                        {/* <i className="ti-check mr-2" /> */}
                        <span>Be a resident in Arizona</span>
                      </li>
                      <li>
                        {/* <i className="ti-check mr-2" /> */}
                        <span>Have a social security number</span>
                      </li>
                      <li>
                        {/* <i className="ti-check mr-2" /> */}
                        <span>
                          Have a regular income, and be able to afford your loan
                          monthly repayment if you don’t pay
                        </span>
                      </li>
                      <li>
                        {/* <i className="ti-check mr-2" /> */}
                        <span>Have a good credit score</span>
                      </li>
                      <li>
                        {/* <i className="ti-check mr-2" /> */}
                        <span>
                          Have a US bank account where we can deposit your loan
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <p className="lead-3">
                  It’s important to choose someone you trust and who knows you
                  well. The loan proceeds will be disbursed to your guarantor’s
                  bank account, and it will be their responsibility to give the
                  funds to you. The guarantor should also feel comfortable to
                  step in to make the payments if you don’t.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="section text-white bg-gray py-7 text-center">
          <div className="container">
            <div className="row">
              <div className="col-md-4 mx-auto">
                <Link
                  className="btn btn-xl btn-rounded btn-danger"
                  style={{
                    transform: "scale(1.3)",
                    transformOrigin: "center",
                  }}
                  to="/apply"
                  onClick={e => {
                    e.preventDefault()
                    document.body.scrollTop = 0
                    document.documentElement.scrollTop = 0
                  }}
                >
                  Get Started
                </Link>
              </div>
            </div>
          </div>
        </section>

        <section className="section py-7 text-center">
          <div className="container">
            <h3 className="fw-600 mb-6 display-5">
              Do I need a guarantor to start the loan application?
            </h3>
            <div className="row">
              <div className="col-md-10 mx-auto">
                <p className="lead-4">
                  No, you do not. While having a guarantor will speed up the
                  decision on your loan application, it’s not required to have a
                  guarantor when you apply. You can start your loan application,
                  get a decision in principle and choose your loan offer. We
                  will then send you a link that you can share with your
                  prospective guarantor so that they can submit their guarantor
                  application.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="section py-7 text-center">
          <div className="container">
            <h3 className="mb-3 display-5">Have questions?</h3>
            <p className="mb-6 lead-2">We’ve got the answers</p>
            <div className="row gap-y">
              <div className="col-md-6 col-xl-3">
                <div className="card card-body shadow-3 text-center px-6">
                  <p className="my-5">
                    <img
                      style={{
                        width: "auto",
                        height: "60px",
                      }}
                      src={withPrefix("/icons8-user-group-2-64.png")}
                      alt="Finding a Guarantor"
                    />
                  </p>
                  <h5 className="mb-5 fw-500">
                    <Link to="/hub">Finding a Guarantor</Link>
                  </h5>
                  <Link to="/hub" className="btn btn-outline-dark">
                    Learn more
                  </Link>
                </div>
              </div>
              <div className="col-md-6 col-xl-3">
                <div className="card card-body shadow-3 text-center px-6">
                  <p className="my-5">
                    <img
                      style={{
                        width: "auto",
                        height: "60px",
                      }}
                      src={withPrefix("/icons8-guarantee-64.png")}
                      alt="Becoming a Guarantor"
                    />
                  </p>
                  <h5 className="mb-5 fw-500">
                    <Link to="/hub">Becoming a Guarantor</Link>
                  </h5>
                  <Link to="/hub" className="btn btn-outline-dark">
                    Learn more
                  </Link>
                </div>
              </div>
              <div className="col-md-6 col-xl-3">
                <div className="card card-body shadow-3 text-center px-6">
                  <p className="my-5">
                    <img
                      style={{
                        width: "auto",
                        height: "60px",
                      }}
                      src={withPrefix("/icons8-apply-50.png")}
                      alt="Applying for a Loan"
                    />
                  </p>
                  <h5 className="mb-5 fw-500">
                    <Link to="/hub">Applying for a Loan</Link>
                  </h5>
                  <Link to="/hub" className="btn btn-outline-dark">
                    Learn more
                  </Link>
                </div>
              </div>
              <div className="col-md-6 col-xl-3">
                <div className="card card-body shadow-3 text-center px-6">
                  <p className="my-5">
                    <img
                      style={{
                        width: "auto",
                        height: "60px",
                      }}
                      src={withPrefix("/icons8-product-documents-64.png")}
                      alt="Rates & Terms"
                    />
                  </p>
                  <h5 className="mb-5 fw-500">
                    <Link to="/hub">Rates & Terms</Link>
                  </h5>
                  <Link to="/hub" className="btn btn-outline-dark">
                    Learn more
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section pt-2 pb-7 text-center">
          <div className="container">
            <div className="row">
              <div className="col-md-4 mx-auto">
                <Link
                  className="btn btn-xl btn-rounded btn-danger"
                  style={{
                    transform: "scale(1.3)",
                    transformOrigin: "center",
                  }}
                  to="/hub"
                >
                  Visit Q&A Hub
                </Link>
              </div>
            </div>
          </div>
        </section>
      </Layout>
      <Seo
        title="Trustic | Borrow affordably with a guarantor!"
        description="Apply for an affordable personal loan with a guarantor today. Borrow up to $3,000, APR under 35.99%, 36 fixed monthly repayments. Next day funding."
      />
    </>
  )
}

export default QuarantorPersonalLoans
